import { Injectable, Signal, WritableSignal, computed, signal } from '@angular/core';
import { OrganisationStructure } from 'app/api';
import { OrgSysAreaTreeNode } from './lib-org-sys-area.types';

@Injectable({
    providedIn: 'root',
})
export class OrgSysAreaTreeService<T> {
    private _treeNodes: Signal<OrgSysAreaTreeNode[]> = computed(() =>
        this.createTree(this.orgData())
    );

    orgData: WritableSignal<T[]> = signal([]);

    get treeNodes() {
        return this._treeNodes;
    }

    isOrganisation(obj: any): obj is OrganisationStructure {
        return obj.constructor.name === 'OrganisationStructure';
    }
    createCollectiveRootNodes(items: T[]): OrgSysAreaTreeNode[] {
        let treeNodes: OrgSysAreaTreeNode[] = [];

        items.forEach(item => {
            const rootNode = treeNodes.find(node => node.id === item['id']);
            if (rootNode) {
                rootNode.children.push(this.buildSystemTreeNode(item));
            } else {
                let node: OrgSysAreaTreeNode = {
                    id: item['id'],
                    title: item['title'],
                    type: 'root',
                    meta: {
                        checkbox: false,
                    },
                    children: [this.buildSystemTreeNode(item)],
                };

                treeNodes.push(node);
            }
        });

        return treeNodes.map(node => {
            if (node.children) {
                node.children = node.children.sort(
                    (a, b) =>
                        new Date(b.meta['updated']).getTime() -
                        new Date(a.meta['updated']).getTime()
                );
            }
            return node;
        });
    }

    createTree(items: T[]) {
        let treeNodes = [];
        if (!items || items.length === 0) return treeNodes;

        treeNodes = this.isOrganisation(items[0])
            ? items.map(organisation => this.buildSystemTreeNode(organisation))
            : this.createCollectiveRootNodes(items);
        return treeNodes;
    }

    buildSystemTreeNode(organisation: T): OrgSysAreaTreeNode {
        let node: OrgSysAreaTreeNode = {
            id: organisation['versionId']
                ? `${organisation['versionId']}_${organisation['id']}`
                : organisation['id'],
            title: `${organisation['title']} ${organisation['version'] ? ` - ${organisation['version']}` : ''}`,
            type: organisation['type'] ?? 'root',
            meta: {
                ...organisation['metadata'],
                status: organisation['status'],
                updated: organisation['updated'],
            },
        };
        if (organisation['children']) {
            node.children = organisation['children'].map(subOrganisation =>
                this.buildOrganisationSubTreeNode(subOrganisation, organisation['versionId'])
            );
        }
        return node;
    }

    buildOrganisationSubTreeNode(organisation: T, versionId?: string): OrgSysAreaTreeNode {
        let node: OrgSysAreaTreeNode = {
            id: versionId ? `${versionId}_${organisation['id']}` : organisation['id'],
            title: organisation['title'],
            type: organisation['type'],
            meta: { ...organisation['metadata'], status: organisation['status'] },
        };
        if (organisation['children']) {
            node.children = organisation['children'].map(organisation =>
                this.buildOrganisationSubTreeNode(organisation, versionId)
            );
        }
        return node;
    }

    checkIfContextMenuItemVisible(node: T): boolean {
        if (!node) {
            return false;
        }
        if (node['status']) {
            return node['status'] === 'DEVELOPMENT';
        }
        const root = this.findRootParentNode(node['id']);

        if (root && root['versionId']) {
            return root['status'] === 'DEVELOPMENT';
        }
        return true;
    }

    findRootParentNode(selectedNodeId: string): T | null {
        let currentNode = this.findParentNode(this.orgData(), selectedNodeId);

        return currentNode;
    }

    findParentNode(tree: T[], selectedNodeId: string): T | null {
        if (!selectedNodeId) {
            return null;
        }

        let versionId: string | undefined;
        let id = selectedNodeId;

        // Split selectedNodeId if it contains a '_'
        const parts = selectedNodeId.split('_');
        if (parts.length === 2) {
            versionId = parts[0];
            id = parts[1];
        }

        // Check if selectedNodeId matches the root node directly
        for (let node of tree) {
            if (node['id'] === id && (!versionId || node['versionId'] === versionId)) {
                return node; // Root node found, and it has no parent
            }

            if (versionId && node['versionId'] && node['versionId'] !== versionId) {
                continue;
            }

            if (node['children'] && node['children'].length > 0) {
                // Check if current node's children contain the selected node
                const foundChild = node['children'].find(child => child.id === id);
                if (foundChild) {
                    return node; // Current node is the parent of the selected node
                }

                // Recursively search in children, passing full selectedNodeId
                const parent = this.findParentNode(node['children'], selectedNodeId);
                if (parent) {
                    return node; // Parent found at a deeper level
                }
            }
        }

        return null; // Parent not found
    }

    findDirectParentNode(tree: T[], selectedNodeId: string): T | null {
        let versionId: string;
        let id = selectedNodeId;

        if (!selectedNodeId) {
            return null;
        }

        // Split the selectedNodeId if it contains an underscore
        if (selectedNodeId.includes('_')) {
            versionId = selectedNodeId.split('_')[0];
            id = selectedNodeId.split('_')[1];
        }

        // Traverse through the tree to find the direct parent node
        for (let node of tree) {
            // Skip if versionId does not match
            if (versionId && node['versionId'] && node['versionId'] !== versionId) {
                continue;
            }

            if (node['children'] && node['children'].length > 0) {
                // Check if any child node has the selected id
                const foundChild = node['children'].find(child => child.id === id);

                if (foundChild) {
                    return node; // Return the direct parent if found in the first level of children
                }

                // Recursively check deeper levels but return if found at any level
                const parent = this.findDirectParentNode(node['children'], selectedNodeId);
                if (parent) {
                    return parent;
                }
            }
        }

        return null; // Parent not found
    }
}
