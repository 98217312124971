import { Injectable, Injector, Signal, WritableSignal, computed, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ContextMenuItem } from '@em4cloud/my-cdk';
import {
    ObjectExplorerItemType,
    OrganisationControllerService,
    OrganisationStructure,
    OrganisationStructureControllerService,
    OrganisationSubType,
    SimpleOrganisation,
} from 'app/api';
import { ActionDescriptor } from 'app/core/dialogBuilder/dialog-builder.models';
import { Observable, map } from 'rxjs';
import { OrgSysAreaService } from '../org-sys-area.service';
import { RoleService } from 'app/core/role-management/role-management.service';

@Injectable({ providedIn: 'root' })
export class OrgService extends OrgSysAreaService<
    OrganisationStructure,
    OrganisationStructureControllerService
> {
    simpleOrganisations: WritableSignal<SimpleOrganisation[]> = signal([]);

    allowedSelectionList: string[] = [
        'organisation',
        'account',
        'department',
        'agent',
        'aiagent',
        'ai agent',
        'root',
    ];

    contextMenuItems: Signal<ContextMenuItem<any>[]> = computed(() => [
        {
            id: 'createSiblingItem',
            title: this._translocoContent()['workflow.createSiblingItem'],
            isVisible:
                this.checkIfContextMenuItemVisible.bind(this) &&
                this.visibilityOfDevelopmentFunctionalities(),
        },
        {
            id: 'createChildItem',
            title: this._translocoContent()['workflow.createChildItem'],
            isVisible:
                this.checkIfContextMenuItemVisible.bind(this) &&
                this.visibilityOfDevelopmentFunctionalities(),
        },
        {
            id: 'delete',
            title: this._translocoContent()['delete'],
            isVisible:
                this.checkIfContextMenuItemVisible.bind(this) &&
                this.visibilityOfDevelopmentFunctionalities(),
        },
    ]);

    private checkIfContextMenuItemVisible(node: OrganisationStructure): boolean {
        if (node.status) {
            return node.status === 'DEVELOPMENT';
        }
        const root = this.treeService.findRootParentNode(node.id);
        return root.status === 'DEVELOPMENT';
    }

    protected override objectExplorerItemType: ObjectExplorerItemType =
        ObjectExplorerItemType.Organisation;

    private _orgService: OrganisationControllerService;
    constructor(inject: Injector) {
        super(inject);
        this._apiService = inject.get(OrganisationStructureControllerService);
        this._orgService = inject.get(OrganisationControllerService);

        this.defaultConstructor();
    }

    override getAll(): void {}

    override initializeData(filterToActive?: boolean): void {
        if (!this.simpleOrganisations().length) {
            this.getSimpleOrganisations()
                .pipe(takeUntilDestroyed(this._destroyRef))
                .subscribe(simpleOrgs => {
                    this.simpleOrganisations.set(simpleOrgs);
                    super.initializeData();
                });
            return;
        } else {
            super.initializeData(filterToActive);
        }
    }

    override findRootParentNode(id: string): OrganisationStructure {
        return this.treeService.findRootParentNode(id);
    }

    private getSimpleOrganisations(): Observable<SimpleOrganisation[]> {
        return this._orgService.getOrganisations();
    }

    protected setUpCreateNewItemActions(): ActionDescriptor[] {
        return [
            {
                code: 'organisation',
                style: 'icon-button',
                tooltip: this._translocoContent()['createOrganisation'],
                icon: 'heroicons_outline:building-office-2',
                manualClose: true,
                // hide: !this.activeKeys().includes('organisation'),
            },
            {
                code: 'department',
                style: 'icon-button',
                tooltip: this._translocoContent()['createDepartment'],
                icon: 'heroicons_outline:building-office',
                manualClose: true,
                // hide: !this.activeKeys().includes('department'),
            },
            {
                code: 'account',
                style: 'icon-button',
                tooltip: this._translocoContent()['createAccount'],
                icon: 'heroicons_outline:user-group',
                manualClose: true,
                // hide: !this.activeKeys().includes('account'),
            },
            {
                code: 'agent',
                style: 'icon-button',
                tooltip: this._translocoContent()['createAgent'],
                icon: 'heroicons_outline:user',
                manualClose: true,
                // hide: !this.activeKeys().includes('agent'),
            },
            {
                code: 'aiagent',
                style: 'icon-button',
                tooltip: this._translocoContent()['createAiAgent'],
                icon: 'heroicons_outline:computer-desktop',
                manualClose: true,
                // hide: !this.activeKeys().includes('aiagent'),
            },
        ];
    }

    protected canBeDeleted(isRoot: boolean): boolean {
        return !isRoot;
    }
    protected getOrganisationIdForNewItem(): string {
        return null;
    }
    getNodeIcons(nodeType: string): string {
        if (!nodeType) {
            nodeType = 'root';
        }
        switch (nodeType.toLowerCase()) {
            case 'root':
            case 'organisation':
                return 'heroicons_outline:building-office-2';
            case 'department':
                return 'heroicons_outline:building-office';
            case 'account':
                return 'heroicons_outline:user-group';
            case 'agent':
                return 'heroicons_outline:user';
            case 'aiagent':
            case 'ai agent':
                return 'heroicons_outline:computer-desktop';
        }
    }

    private lowerCaseOrganisationTypeDeep(
        organisation: OrganisationSubType
    ): OrganisationStructure {
        if (!organisation.type) {
            return organisation;
        }
        organisation.type = organisation.type.toLowerCase();

        if (!organisation.children) {
            organisation.children = [];
        }
        organisation.children?.forEach(child => {
            child = this.lowerCaseOrganisationTypeDeep(child);
        });

        return organisation;
    }

    private modifyOrganisationIdDeep(organisation: OrganisationSubType): OrganisationStructure {
        organisation.id = organisation.id.includes('_')
            ? organisation.id.split('_')[1]
            : organisation.id;

        organisation.children?.forEach(child => {
            child = this.modifyOrganisationIdDeep(child);
        });

        return organisation;
    }

    private modifyEmployeesDeep(organisation: OrganisationSubType): OrganisationStructure {
        const employees = organisation.metadata?.['employees'];
        if (employees?.length) {
            employees.forEach(employee => {
                delete employee.x;
                delete employee.y;
            });
            organisation.metadata['employees'] = employees;
        }

        organisation.children?.forEach(child => {
            child = this.modifyOrganisationIdDeep(child);
        });

        return organisation;
    }

    protected handleGetResult(orgId: string, data: [OrganisationStructure[]]): void {
        data[0] = data[0].sort(
            (a, b) => new Date(b.updated).getTime() - new Date(a.updated).getTime()
        );

        const isAdmin = this.roleService.hasAnyRole(['ADM', 'CON']);
        const filteredData = data[0].filter(
            item => (!isAdmin && item.status === 'ACTIVE') || isAdmin
        );

        this.filteredItems.set(filteredData);
        this._items.set(data[0]);

        if (this.selectedItem()) {
            this.selectItemById(this.selectedItem().id);
        }
    }
    protected createItemWithAPI(item: OrganisationStructure): Observable<OrganisationStructure> {
        item.children.forEach(child => {
            child = this.lowerCaseOrganisationTypeDeep(child);
            child = this.modifyOrganisationIdDeep(child);
            child = this.modifyEmployeesDeep(child);
        });
        return this._apiService.createOrganisationStructure(item);
    }
    protected updateItemWithAPI(
        items: OrganisationStructure[]
    ): Observable<OrganisationStructure[]> {
        items.forEach(item => {
            item = this.lowerCaseOrganisationTypeDeep(item);
            item = this.modifyOrganisationIdDeep(item);
            item = this.modifyEmployeesDeep(item);
        });
        return this._apiService.updateOrganisationStructure(items);
    }
    protected deleteItemWithAPI(id: string, organisation?: string): Observable<string> {
        return this._apiService.deleteOrganisationStructure(id);
    }
    protected getItemsWithAPI(organisation?: string): Observable<OrganisationStructure[]> {
        if (!organisation) {
            organisation = localStorage.getItem('organisation');
        }
        return this._apiService
            .getOrganisationStructure(organisation)
            .pipe(
                map(organisations =>
                    organisations.map(organisation =>
                        this.lowerCaseOrganisationTypeDeep(organisation)
                    )
                )
            );
    }
    protected getActiveItemWithAPI(organisation?: string): Observable<OrganisationStructure[]> {
        if (!organisation) {
            organisation = localStorage.getItem('organisation');
        }
        return this._apiService
            .getOrganisationStructure(organisation, 'ACTIVE')
            .pipe(
                map(organisations =>
                    organisations.map(organisation =>
                        this.lowerCaseOrganisationTypeDeep(organisation)
                    )
                )
            );
    }
    protected activateWithAPI(id: string, versionId: string): Observable<OrganisationStructure> {
        return this._apiService
            .activateOrganisationStructure(id, versionId)
            .pipe(map(organisation => this.lowerCaseOrganisationTypeDeep(organisation)));
    }
}
