import {
    DestroyRef,
    Injectable,
    Injector,
    Signal,
    WritableSignal,
    computed,
    effect,
    signal,
    untracked,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { ContextMenuItem, ContextMenuItemClickData } from '@em4cloud/my-cdk';
import { TranslocoService } from '@ngneat/transloco';
import { ObjectExplorerItem, ObjectExplorerItemType } from 'app/api';
import { AlertService } from 'app/core/alert/alert.service';
import { ActionDescriptor, ButtonType } from 'app/core/dialogBuilder/dialog-builder.models';
import { DialogBuilderService } from 'app/core/dialogBuilder/dialog-builder.service';
import { GloballySelectedOrgService } from 'app/layout/common/organisation-filter/globally-selected-org.service';
import { ObjectExplorerService } from 'app/modules/object-explorer/object-explorer.service';
import {
    ConfirmationCloseAction,
    ConfirmationService,
} from 'app/services/confirmation/confirmation.service';
import { cloneDeep } from 'lodash';
import { Observable, forkJoin } from 'rxjs';
import {
    OrgSysAreaFlatTreeNode,
    OrgSysAreaTreeNodeType,
} from './lib-org-sys-area-tree/lib-org-sys-area.types';
import { OrgSysAreaTreeDialogComponent } from './org-sys-area-tree-dialog/org-sys-area-tree-dialog.component';
import { LanguageChangerService } from 'app/services/translate_api/languageChanger.service';
import { RoleService } from 'app/core/role-management/role-management.service';
import { OrgSysAreaTreeService } from './lib-org-sys-area-tree/lib-org-sys-area.service';

@Injectable()
export abstract class OrgSysAreaService<T, S> {
    protected objectExplorerItemType: ObjectExplorerItemType;

    // #region Selected item
    private _selectedItem: WritableSignal<T> = signal(null);

    get selectedItem(): Signal<T> {
        return this._selectedItem.asReadonly();
    }
    set selectedItem(item: T) {
        this._selectedItem.set(item);
    }

    visibilityOfDevelopmentFunctionalities = computed(() => {
        let visible = false;

        if (this.selectedItem()) {
            visible = this.treeService.checkIfContextMenuItemVisible(this.selectedItem());
        }

        return visible && this.roleService.hasAnyRole(['ADM', 'CON']);
    });

    selectItemById(id: string): void {
        const selectedItem: T = { ...this.findDeep(id, this.state()) };
        (selectedItem as any).id = id;
        this.selectedItem = selectedItem;
    }

    // #endregion

    // #region Type defs
    private _typeDefs: Signal<ObjectExplorerItem[]> = computed(() =>
        this._objectExplorerService
            .objectExplorerItems()
            ?.filter(item => item.type === this.objectExplorerItemType)
    );
    get typeDefs(): Signal<ObjectExplorerItem[]> {
        return this._typeDefs;
    }

    activeKeys = computed(() =>
        this.typeDefs()
            .filter(item => item.status === 'Active')
            .map(item => item.id.toLowerCase() as string)
    );
    // #endregion

    // #region States
    protected _items: WritableSignal<T[]> = signal(null);

    set items(value: T[]) {
        this._items.set(value);
    }

    get items() {
        return this._items();
    }

    get state(): Signal<T[]> {
        return this._items.asReadonly();
    }
    set state(items: T[]) {
        this._items.set([...items]);
    }

    updateState(fn: (value: T[]) => T[]) {
        this._items.update(fn);
    }
    // #endregion

    filteredItems: WritableSignal<T[]> = signal(null);

    private _globallySelectedOrganisationId: Signal<string>;
    public get globallySelectedOrganisationId(): Signal<string> {
        return this._globallySelectedOrganisationId;
    }

    previousOrgId: string;

    executeActionSignal: WritableSignal<string> = signal(null);

    _translocoContent = signal(null);

    private dialogRef: MatDialogRef<any, any>;
    dialogResult: T;
    newItemId?: string;

    abstract allowedSelectionList: string[];
    abstract contextMenuItems: Signal<ContextMenuItem<any>[]>;

    createNewItemActions: Signal<ActionDescriptor[]>;

    protected _apiService: S;
    protected _objectExplorerService: ObjectExplorerService;
    protected _destroyRef: DestroyRef;
    protected _alertService: AlertService;
    protected _dialogService: DialogBuilderService;
    protected _dialogService2: ConfirmationService;
    protected _translocoService: TranslocoService;
    protected _globallySelectedOrgService: GloballySelectedOrgService;
    protected _languageChangerService: LanguageChangerService;
    protected roleService: RoleService;
    protected treeService: OrgSysAreaTreeService<T>;
    constructor(protected inject: Injector) {}

    protected defaultConstructor(): void {
        this.injectServices();

        this._globallySelectedOrganisationId = computed(() => {
            return this._globallySelectedOrgService.selectedOrganisation();
        });

        this.createNewItemActions = computed(() => {
            return this.setUpCreateNewItemActions();
        });

        effect(
            () => {
                const orgId = this.globallySelectedOrganisationId();

                if (this.globallySelectedOrganisationId() && orgId !== this.previousOrgId) {
                    this.initializeData();
                    this._selectedItem.set(null);
                    this.previousOrgId = orgId;
                }
            },
            {
                allowSignalWrites: true,
                injector: this.inject,
            }
        );

        effect(
            () => {
                if (this.filteredItems()?.length) {
                    setTimeout(() => {
                        this.autoSelectFirstItem();
                    }, 100);
                }
            },
            {
                allowSignalWrites: true,
            }
        );

        this.getAll();

        this._translocoService
            .selectTranslation('bpOrganisation')
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(content => {
                this._translocoContent.set(content);
            });

        this._languageChangerService.register(
            `initialise_org_sys_area_${this.objectExplorerItemType}`,
            this.initializeData.bind(this)
        );
    }

    private injectServices(): void {
        this._objectExplorerService = this.inject.get(ObjectExplorerService);
        this._destroyRef = this.inject.get(DestroyRef);
        this._alertService = this.inject.get(AlertService);
        this._dialogService = this.inject.get(DialogBuilderService);
        this._dialogService2 = this.inject.get(ConfirmationService);
        this._translocoService = this.inject.get(TranslocoService);
        this._globallySelectedOrgService = this.inject.get(GloballySelectedOrgService);
        this._languageChangerService = this.inject.get(LanguageChangerService);
        this.roleService = this.inject.get(RoleService);
        this.treeService = this.inject.get(OrgSysAreaTreeService<T>);
    }

    initializeData(filterToActive?: boolean) {
        if (!this.globallySelectedOrganisationId()) {
            return;
        }
        forkJoin([
            filterToActive
                ? this.getActiveItemWithAPI(this.globallySelectedOrganisationId())
                : this.getItemsWithAPI(this.globallySelectedOrganisationId()),
        ])
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((data: [T[]]) => {
                this.handleGetResult(this.globallySelectedOrganisationId(), data);
            });
    }

    protected abstract handleGetResult(orgId: string, data: [T[]]): void;

    protected abstract setUpCreateNewItemActions(): ActionDescriptor[];

    getAll() {
        this.getItemsWithAPI(this.globallySelectedOrganisationId()).subscribe(data => {
            this._items.set(data);

            if (this.newItemId) {
                this.selectItemById(this.newItemId);
                this.newItemId = undefined;
            }
        });
    }

    public findRootParentNode(id: string): T {
        throw new Error('Method not implemented.');
    }

    /**
     * Finds an item by its id in the given array of items, or if the given id is a versioned id, it finds the item by its versionId and id.
     * If the item is not found in the given array, it will be searched in the children of all items in the array.
     * @param id The id of the item to find, or a versioned id in the form of "versionId_id".
     * @param items The array of items to search in.
     * @returns The found item, or null if the item was not found.
     */
    private findDeep(id: string, items: T[]): T {
        let versionId: string;
        if (id.includes('_')) {
            versionId = id.split('_')[0];
            id = id.split('_')[1];
        }

        for (let item of items) {
            if (versionId && item['versionId'] && item['versionId'] !== versionId) {
                continue;
            }

            if (item['id'] === id) return item;

            if (item['children']?.length > 0) {
                let subItem = this.findDeep(id, item['children']);
                if (subItem) return subItem;
            }
        }
        return null;
    }

    getItemById(id: string): T {
        return this.findDeep(id, this.state());
    }

    menuItemClicked(
        $event: ContextMenuItemClickData<OrgSysAreaFlatTreeNode>,
        type?: 'org' | 'sys' | 'area'
    ) {
        const id: string = $event.menuItem.id;
        if (id === 'delete') {
            this.delete($event.node, type);
        }
    }

    async newItem(node?: OrgSysAreaFlatTreeNode, type?: OrgSysAreaTreeNodeType | string) {
        const organisationId = this.globallySelectedOrganisationId();
        this.dialogRef = this._dialogService.openDialog({
            descriptor: {
                executeActionSignal: this.executeActionSignal,
                dialogSize: 'l',
                header: {
                    title: this._translocoContent()[`createTitle`],
                    showCloseButton: true,
                },
                content: {
                    componentConfig: {
                        component: OrgSysAreaTreeDialogComponent,
                        componentData: {
                            node: node,
                            type: type,
                            service: this,
                            organisationId,
                        },
                    },
                },
                actions: {
                    dialogActions: [
                        {
                            code: 'close',
                            color: 'secondary',
                            style: ButtonType.simple,
                            title: this._translocoContent()['cancel'],
                        },
                        {
                            code: 'save',
                            color: 'primary',
                            style: ButtonType.raised,
                            title: this._translocoContent()['createButton'],
                            manualClose: true,
                        },
                    ],
                },
            },
        });

        return this.dialogRef
            .beforeClosed()
            .toPromise()
            .then(() => {
                if (this.dialogResult) {
                    if (this.dialogResult['children']?.length) {
                        this.updateItem(this.dialogResult);
                    } else {
                        this.createItem(this.dialogResult);
                    }
                    this.dialogResult = undefined;
                }
            });
    }

    delete(node?: OrgSysAreaFlatTreeNode, type?: 'org' | 'sys' | 'area') {
        const itemId = node ? node.id : this.selectedItem()['id'];
        if (!itemId) return;
        if (!node) {
            node = this.selectedItem() as any;
        }

        const state = cloneDeep(untracked(this.state));

        let rootParentId = this.removeChildByIdAndGetFirstLevelParentId(state, itemId);

        if (rootParentId === null) return; // no element present with the orgId
        const isRoot = rootParentId ? false : true;

        rootParentId = rootParentId || itemId;

        const rootParent = state.find(item => item['id'] === rootParentId);

        const config = {
            title: 'delete',
            message: 'deleteMessage',
            actions: {
                confirm: {
                    label: 'delete',
                },
            },
        };
        this._dialogService2
            .open(config)
            .afterClosed()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((action: ConfirmationCloseAction) => {
                if (action === 'confirmed') {
                    if (this.canBeDeleted(isRoot) && isRoot && type === 'org') {
                        this.deleteRoot(node, this.globallySelectedOrganisationId());
                    } else if (this.canBeDeleted(isRoot) && !isRoot) {
                        this.updateItem(rootParent);
                    } else {
                        this._alertService.errorAlert(
                            'bpOrganisation',
                            'rootNotDeletable',
                            'rootNotDeletableTitle'
                        );
                    }
                }
            });
    }

    updateItem(rootParent: T): void {
        this.updateItems([rootParent]);
    }

    updateItems(items: T[]): void {
        this.updateItemWithAPI(items)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                this.initializeData();
                this.getAll();

                this._alertService.successAlert(
                    'bpOrganisation',
                    'organisationUpdatedDescription',
                    'organisationUpdatedTitle'
                );
            });
    }

    createItem(item: T): void {
        this.createItemWithAPI(item, this.globallySelectedOrganisationId())
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                this.initializeData();
                this.getAll();

                this._alertService.successAlert(
                    'bpOrganisation',
                    'organisationUpdatedDescription',
                    'organisationUpdatedTitle'
                );
            });
    }

    private removeChildByIdAndGetFirstLevelParentId(
        nestedObjects: T[],
        targetId: string,
        firstLevelParentId: string = '',
        currentLevel: number = 0
    ): string | null {
        let id = targetId;
        let versionId: string;

        if (id.includes('_')) {
            versionId = id.split('_')[0];
            id = id.split('_')[1];
        }

        for (let i = 0; i < nestedObjects.length; i++) {
            const obj = nestedObjects[i];

            if (versionId && obj['versionId'] && obj['versionId'] !== versionId) {
                continue;
            }

            if (obj['id'] === id) {
                nestedObjects.splice(i, 1);
                return firstLevelParentId;
            } else if (obj['children']) {
                const newFirstLevelParentId = currentLevel === 0 ? obj['id'] : firstLevelParentId;
                const result = this.removeChildByIdAndGetFirstLevelParentId(
                    obj['children'],
                    targetId,
                    newFirstLevelParentId,
                    currentLevel + 1
                );
                if (result) return result;
            }
        }
        return null;
    }

    treeSelectionId: string;
    treeSelection: OrgSysAreaFlatTreeNode;
    setSelectedItem(node: OrgSysAreaFlatTreeNode) {
        this.treeSelectionId = node?.id;
        this.treeSelection = node;
        if (node) {
            let item = this.findDeep2(node, this.state());
            if (item) this.selectedItem = item;
        } else {
            this.selectedItem = null;
        }
    }

    findDeep2(node: OrgSysAreaFlatTreeNode, state: T[]): T {
        let id = node.id;
        let versionId: string;

        if (id.includes('_')) {
            versionId = id.split('_')[0];
            id = id.split('_')[1];
        }
        for (let item of state) {
            if (versionId && item['versionId'] && item['versionId'] !== versionId) {
                continue;
            }
            if (item['id'] === id || item['versionId'] === id) return item;

            if (item['children']?.length > 0) {
                let subItem = this.findDeep2(node, item['children']);
                if (subItem) return subItem;
            }
        }
        return null;
    }

    deleteRoot(item: OrgSysAreaFlatTreeNode, organisation: string): void {
        this.deleteItemWithAPI(item.id, organisation)
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(() => {
                this.initializeData();
                this.getAll();

                this._alertService.successAlert(
                    'bpOrganisation',
                    'organisationDeletedDescription',
                    'organisationDeletedTitle'
                );
            });
    }

    activate(): void {
        this._dialogService2
            .open({
                title: 'activate',
                message: 'activateMessage',
                icon: {
                    color: 'primary',
                },
                actions: {
                    confirm: {
                        label: 'activate',
                        color: 'primary',
                    },
                },
            })
            .afterClosed()
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe((action: ConfirmationCloseAction) => {
                if (action === 'confirmed') {
                    const selectedItem = this.selectedItem();
                    if (!selectedItem) {
                        console.error('No activable item is selected!');
                        return;
                    }

                    let id = selectedItem['id'].includes('_')
                        ? selectedItem['id'].split('_')[1]
                        : selectedItem['id'];

                    this.activateWithAPI(id, selectedItem['versionId'])
                        .pipe(takeUntilDestroyed(this._destroyRef))
                        .subscribe(response => {
                            this.initializeData();
                            this.getAll();
                            this._alertService.successAlert(
                                'bpOrganisation',
                                'organisationActivatedDescription',
                                'organisationActivatedTitle'
                            );
                            this.setSelectedItem(null);
                        });
                }
            });
    }

    public autoSelectFirstItem(): void {
        if (!this.filteredItems() || !this.filteredItems().length || this.selectedItem()) {
            return;
        }
        const activeItems = this.filteredItems().filter(
            (item: any) => item && item.status === 'ACTIVE'
        );
        const itemToSelect: any = activeItems.length > 0 ? activeItems[0] : this.filteredItems()[0];
        if (itemToSelect) {
            this.selectItemById(`${itemToSelect.versionId}_${itemToSelect.id}`);
        }
    }

    abstract getNodeIcons(nodeType: OrgSysAreaTreeNodeType | string): string;
    protected abstract canBeDeleted(isRoot: boolean): boolean;

    // #region Outsource API calls
    protected abstract createItemWithAPI(item: T, organisation?: string): Observable<T>;
    protected abstract updateItemWithAPI(items: Array<T>): Observable<Array<T>>;
    protected abstract deleteItemWithAPI(id: string, organisation: string): Observable<string>;
    protected abstract getItemsWithAPI(organisation: string): Observable<Array<T>>;
    protected abstract getActiveItemWithAPI(organisation?: string): Observable<Array<T>>;
    protected abstract activateWithAPI(id: string, versionId: string): Observable<T>;
    // #endregion
}
