<div class="flex flex-col gap-2">
    <div class="p-2 flex flex-col gap-2">
        <h6> Type </h6>
        <action-bar
            [selectionMode]="true"
            [preselect]="preselection"
            [actionDescriptors]="actions"
            [executeActionSignal]="selectionActionSignal"></action-bar>
    </div>
    <form [formGroup]="form" (ngSubmit)="submit()" class="px-2">
        <formly-form [model]="model()" [fields]="fields()" [form]="form"></formly-form>
    </form>
</div>
