import { CommonModule } from '@angular/common';
import {
    ChangeDetectionStrategy,
    Component,
    DestroyRef,
    OnDestroy,
    Signal,
    WritableSignal,
    computed,
    effect,
    signal,
    untracked,
} from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { OrgSysAreaFormService } from '../org-sys-area.form.service';
import { Overlay } from '@angular/cdk/overlay';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { TRANSLOCO_SCOPE, TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { DialogBuilderComponent } from 'app/core/dialogBuilder/dialog-builder.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { cloneDeep } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import { ActionDescriptor } from 'app/core/dialogBuilder/dialog-builder.models';
import { ActionBarComponent } from 'app/core/dialogBuilder/action-bar/action-bar.component';

@Component({
    selector: 'org-sys-area-tree-dialog',
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        FormlyModule,
        FormlyMaterialModule,
        ActionBarComponent,
        TranslocoModule,
    ],
    templateUrl: './org-sys-area-tree-dialog.component.html',
    styleUrl: './org-sys-area-tree-dialog.component.scss',
    providers: [
        {
            provide: MAT_SELECT_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.reposition(),
            deps: [Overlay],
        },
        { provide: TRANSLOCO_SCOPE, useValue: 'bpOrganisation' },
        OrgSysAreaFormService,
    ],
})
export class OrgSysAreaTreeDialogComponent<T>
    extends DialogBuilderComponent<any>
    implements OnDestroy
{
    form = new FormGroup({});
    model = computed(() => {});
    fields: Signal<FormlyFieldConfig[]>;

    actions: ActionDescriptor[];
    preselection: string;

    _translocoContent = signal(null);

    lastActionExecutedAt: Date | undefined = undefined;

    private _orgService: any;

    selectionActionSignal: WritableSignal<string> = signal(null);

    constructor(
        private _translocoService: TranslocoService,
        private _orgFormService: OrgSysAreaFormService<T>,
        private _destroyRef: DestroyRef
    ) {
        super();
        this._orgFormService.isDialog.set(true);
        this.fields = computed(this._orgFormService.formFields);

        this._translocoService
            .selectTranslation('bpOrganisation')
            .pipe(takeUntilDestroyed(this._destroyRef))
            .subscribe(content => {
                this._translocoContent.set(content);
            });

        effect(
            () => {
                let code = this.executeActionSignal();
                if (code === 'save') {
                    this.submit();
                }
            },
            { allowSignalWrites: true }
        );

        effect(
            () => {
                let code = this.selectionActionSignal();

                this.componentData.type = code;
                this.form = new FormGroup({});
                this._orgFormService.formType.set(this.componentData.type);
            },
            { allowSignalWrites: true }
        );
    }
    ngOnDestroy(): void {
        this.selectionActionSignal.set(null);
        this._orgFormService.isDialog.set(false);
    }

    ngOnInit(): void {
        super.ngOnInit();
        this._orgService = this.componentData.service;
        this._orgFormService.service = this._orgService;

        this.actions = this.componentData.service.createNewItemActions();
        this.preselection = this.actions[0].code;
    }

    submit() {
        if (this.form.invalid) {
            console.error('Form is invalid!', this.form);
            return;
        }

        let newItem: Partial<T> = this.form.value;

        newItem['id'] = uuidv4();

        if (this.componentData.type) newItem['type'] = this.componentData.type;

        if (this.componentData.organisationId) {
            newItem['organisation'] = this.componentData.organisationId;
        }

        let state = cloneDeep(untracked(this._orgService.state)) as T[];

        state = state.filter(item =>
            item['organisation'] ? item['organisation'] === this.componentData.organisationId : true
        );

        if (this.componentData.node) {
            let rootParentId = this.addChildAndReturnRootId(
                state,
                this.componentData.node.id,
                newItem
            );

            rootParentId = rootParentId || this.componentData.node.id;
            let rootParent = state.find(
                org => org['id'] === rootParentId && org['status'] === 'DEVELOPMENT'
            );
            if (!rootParent && this.componentData.node.id.includes('_')) {
                rootParent = state.find(
                    org => org['versionId'] === this.componentData.node.id.split('_')[0]
                );
            }

            this._orgService.dialogResult = rootParent;
            this._orgService.newItemId = newItem['id'];
        } else {
            state.push(newItem as T);
            this._orgService.items = state;
            this._orgService.dialogResult = newItem;
            this._orgService.newItemId = newItem['id'];
        }

        this.closeDialog();
    }

    addChildAndReturnRootId(roots: T[], targetId: string, newChild: Partial<T>): string | null {
        let id: string = targetId;
        let versionId: string;
        if (id.includes('_')) {
            versionId = id.split('_')[0];
            id = id.split('_')[1];
        }

        function findAndModify(node: T, parentId: string | null): string | null {
            if (node['id'] === id) {
                if (!node['children']) node['children'] = [];
                node['children'].push(newChild);
                return parentId;
            }
            if (!node['children'] || node['children'].length == 0) return null;
            for (const child of node['children']) {
                const result = findAndModify(child, node['id']);
                if (result !== null) return result;
            }
            return null;
        }

        for (const root of roots) {
            if (versionId && root['versionId'] && root['versionId'] !== versionId) {
                continue;
            }
            const result = findAndModify(root, null);
            if (result !== null) return root['id'];
        }

        return null;
    }
}
